<template>
  <v-card width="400" class="mx-auto mt-5">
    <span v-if="alert !== ''" class="error--text">{{ alert }}</span>
    <v-card-text>
      <v-form>
        <v-text-field
          :label="$t('userRegistration.password')"
          v-model="password"
          type="password"
          prepend-icon="mdi-lock"
        ></v-text-field>
        <v-text-field
          :label="$t('userRegistration.repeatPassword')"
          v-model="passwordVerify"
          type="password"
          prepend-icon="mdi-lock"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn class="success" @click="checkAndChangePassword">{{
        $t('userRegistration.setPassword')
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      passwordVerify: '',
      alert: '',
    }
  },
  methods: {
    checkAndChangePassword() {
      this.alert = ''
      if (this.password.length < 8) {
        this.alert = this.$i18n.t('validations.passwordLength')
        return
      }
      // Verify that the passwords match
      if (this.password !== this.passwordVerify) {
        this.alert = this.$i18n.t('validations.passwordMatch')
        return
      }
      let payload = { password: this.password, code: this.$route.params.code }
      this.$store.dispatch('user/changePassword', payload)
    },
  },
}
</script>

<style lang="scss" scoped></style>
